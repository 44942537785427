import React from "react";
import { Modal } from "antd";

const GetInTouchModal = ({ isModalOpen, handleOk, handleCancel }) => {
  return (
    <Modal
      className="modal-box"
      title="Get in Touch"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </Modal>
  );
};

export default GetInTouchModal;
